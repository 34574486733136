import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ManifestsService {
    constructor(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
    }
    getTodaysManifests() {
        return this.http.get(`${this.apiUrl}/manifests/today`)
            .pipe(pluck('data'));
    }
}
ManifestsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManifestsService_Factory() { return new ManifestsService(i0.ɵɵinject(i1.HttpClient)); }, token: ManifestsService, providedIn: "root" });
