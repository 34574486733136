import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// models
import { Shipment } from '@app/models/shipment.model';
import { PlanService, ShipmentService } from '@app/services';
import { Subscription as SubscriptionModel } from '@app/models/subscription.model';
import { AlertService } from '@app/shared/components/alerts/alert.service';

@Component({
  selector: 'app-shipment-item',
  templateUrl: './shipment-item.component.html',
  styleUrls: ['./../../dashboard.component.scss', './shipment-item.component.scss']
})
export class ShipmentItemComponent implements OnInit {

  @Output() printDate: EventEmitter<boolean> = new EventEmitter();

  @Input() shipment: Shipment;
  @Input() subscription: SubscriptionModel;
  @Input() giftItMessage: string;
  @Input() showEditMessage: any;


  @Output() buyShipment: EventEmitter<boolean> = new EventEmitter();
  @Input() buyingShipment = false;

  @Output() printLabel: EventEmitter<boolean> = new EventEmitter();
  @Input() printingLabel = false;

  @Output() getLabel: EventEmitter<{ updatePrintDate: boolean }> = new EventEmitter();
  @Input() gettingLabel = false;

  @Output() voidLabel: EventEmitter<boolean> = new EventEmitter();
  @Input() inVoid = false;

  @Output() reshipLabel: EventEmitter<boolean> = new EventEmitter();
  @Input() reshiping = false;
  @Input() printingDate = false;


  @Output() addressChange: EventEmitter<boolean> = new EventEmitter();
  @Output() receiverChange: EventEmitter<boolean> = new EventEmitter();

  editText = false;
  customText: string;
  giftPlanIds: string[];
  cancellingLabel = false;

  constructor(private shipmentService: ShipmentService, private alertService: AlertService, private planService: PlanService) {
  }

  ngOnInit() {
    this.planService.getPlansState().subscribe((plans) => {
      this.giftPlanIds = plans.filter(plan => plan.type === 'GIFT').map(plan => plan.id);
    });
    this.customText = this.shipment.customText || this.shipment.selectedBox && this.shipment.selectedBox.name;
  }

  editBoxText() {
    this.editText = true;
  }

  cancelEditBoxText() {
    this.editText = false;
  }

  saveCustomText() {
    if (this.shipment.selectedBox && this.shipment.selectedBox.name && this.customText &&
      this.customText.includes(this.shipment.selectedBox.name)) {
      this.editText = false;
      console.log('Custom text and box name are the same, skipping new update');
      return;
    }

    this.shipmentService.updateShipment(this.shipment.id, {customText: this.customText}).subscribe(() => {
      this.editText = false;
    });
  }

  removeCustomText() {
    this.shipmentService.updateShipment(this.shipment.id, {$unset: {customText: 1}}).subscribe(() => {
      this.customText = this.shipment.selectedBox && this.shipment.selectedBox.name;
      this.shipment.customText = undefined;
    });
  }

  editGiftMessage(message: string) {
    const alertObject = {
      alertsCode: 'dashboard-alerts',
      title: 'Gift Message',
      message: '',
      timeout: 3000
    };
    this.shipmentService.updateShipment(this.shipment.id, {message,}).subscribe(() => {
      alertObject.message = 'Saved';
      this.alertService.success(alertObject);
    }, () => {
      alertObject.message = 'Error when saving';
      this.alertService.danger(alertObject);
    });

  }

  showEditGiftMessage() {
    const subscription = this.subscription || {plan: ''};
    const shipment = this.shipment;
    const isGiftSubcription = this.giftPlanIds.find(id => id === subscription.plan);
    const isFirstShipment = this.showEditMessage && shipment.isGift && isGiftSubcription;
    const isGiftedShipment = !isGiftSubcription && shipment.isGift;
    return isGiftedShipment || isFirstShipment;
  }

  cancelLabel() {
    this.cancellingLabel = true;
    this.shipmentService.updateShipment(this.shipment.id, {cancelled: true})
      .subscribe(shipment => {
        this.cancellingLabel = false;
        this.shipment.cancelled = true;
      }, error => {
        alert(`We were not able to cancel the shipment`);
        this.cancellingLabel = false;
      });
  }
}
