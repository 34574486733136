import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as AppData from '@config/app.json'

import { Observable } from 'rxjs';

import * as fromAdminActions from './store/actions/admin.actions';
import * as fromAdmin from './store/selectors/admin.selectors';
import * as fromRoot from './store/selectors/index';
// services
import * as fromServices from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  appData = (AppData as any).default;

  admin$: Observable<fromAdmin.State>;

  constructor(
    public router: Router,
    private store: Store<fromRoot.State>,
    private authService: fromServices.AuthService
  ) { }

  get auth(): string {
    return localStorage.getItem('ssops:v0.0.1:li');
  }

  ngOnInit() {
    this.admin$ = this.store.select(fromRoot.getAdminState);
    this.admin$
      .subscribe((admin: fromAdmin.State) => {
        if (!admin.isAuthenticated && this.authService.token) {
          this.store.dispatch(new fromAdminActions.LoadAction());
        }
      });
  }

  logOut() {
    localStorage.removeItem('ssops:v0.0.1:li');
    this.router.navigate(['/login'])
      .then(n => this.store.dispatch(new fromAdminActions.LogoutAction()));
  }
}
