export class Subscription {
  loading: boolean;
  id: number;
  customerId: string;
  createdAt: Date;
  updatedAt: Date;
  cancelledAt: Date;
  completedAt: Date;
  status: string;
  subscriptionDiscount: number;
  recipient: {
    firstName: string,
    lastName: string,
  };
  isGift: boolean;
  giftMessage?: string;
  declineStatus: string;
  nextRetryDate: string;
  address: string;
  giftedShipments: SubscriptionGiftedShipments[];
  nextBillingDate: string;
  billingHz: number;
  plan: string;
  source: string;
  cancelledReasons?: { name: string, value?: string }[];

  autoRenew: boolean;
  turnedOffAt: Date;
  turnedOnAt: Date;

  preferredShipper: {
    carrier: string,
    service: string,
  };
  prepaidShipments: number;
  isPrepay: boolean;

  selectedBox: string;

  constructor(data) {
    const recipient = data.recipient ? data.recipient : {};
    this.loading = false;
    this.id = data._id;
    this.selectedBox = data.selectedBox;
    this.customerId = data.user;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.cancelledAt = data.cancelledAt ? new Date(data.cancelledAt) : null;
    this.completedAt = data.completedAt ? new Date(data.completedAt) : null;
    this.status = data.status;
    this.recipient = {
      firstName: recipient.firstName ? recipient.firstName : '',
      lastName: recipient.lastName ? recipient.lastName : '',
    };
    this.isGift = data.plan.type === 'GIFT';
    this.giftMessage = data.giftMessage;
    this.declineStatus = typeof data.declineStatus !== 'undefined' ? data.declineStatus : '';
    this.giftedShipments = data.giftedShipments.map(gs => new SubscriptionGiftedShipments(gs));
    this.nextBillingDate = data.nextBillingDate;
    this.billingHz = data.billingHz;
    this.plan = data.plan;
    this.source = data.source;
    this.address = data.address;
    this.cancelledReasons = data.canceledReasons;
    this.nextRetryDate = data.nextRetryDate;

    this.subscriptionDiscount = parseFloat(data.subscriptionDiscount);
    this.autoRenew = data.autoRenew;
    this.turnedOffAt = data.turnedOffAt;
    this.turnedOnAt = data.turnedOnAt;
    this.preferredShipper = data.preferredShipper;

    this.prepaidShipments = data.prepaidShipments;
    this.isPrepay = data.isPrepay;
  }
}

export class SubscriptionAddress {
  shippingAddress: string;
  shippingAddressExtra: string;
  line: string;
  formattedLine: string;
  state: string;
  stateCode: string;
  city: string;
  zip: string;
  country: string;
  countryCode: string;
  label: string;

  constructor(data) {
    this.shippingAddress = data.shippingAddress;
    this.shippingAddressExtra = data.shippingAddressExtra;
    this.line = data.line;
    this.formattedLine = data.formattedLine;
    this.state = data.state;
    this.stateCode = data.stateCode;
    this.city = data.city;
    this.zip = data.zip;
    this.country = data.country;
    this.countryCode = data.countryCode;
    this.label = data.label;
  }
}

export class SubscriptionGiftedShipments {
  name: string;
  address: SubscriptionAddress;
  message: string;
  status: string;
  activeGift: boolean | undefined;
  shipment: string;
  subscriptionBillingPeriod: string;
  shipmentDate: string;
  sendShipmentToUser: boolean;

  constructor(data) {
    this.name = data.name;
    this.address = new SubscriptionAddress(data.address);
    this.message = data.message;
    this.status = data.status;
    this.activeGift = data.activeGift;
    this.shipment = data.shipment;
    this.subscriptionBillingPeriod = data.subscriptionBillingPeriod;
    this.shipmentDate = data.shipmentDate;
    this.sendShipmentToUser = data.sendShipmentToUser;
  }
}
