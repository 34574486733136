import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import {
  Observable,
  BehaviorSubject
} from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import { Box } from '@app/models/box.model';

@Injectable()
export class BoxService {
  private apiUrl = environment.apiUrl + '/api';
  private boxesState: Box[] = [];
  private boxesState$: BehaviorSubject<Box[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
  }

  public getBoxesState(): Observable<Box[]> {
    return this.boxesState$;
  }

  public updateBoxesState(data: Box[]) {
    this.boxes = data;
  }

  private set boxes(data: Box[]) {
    this.boxesState = data;
    this.boxesState$.next(data);
  }

  private get boxes(): Box[] {
    return this.boxesState.slice();
  }

  fetchboxes(): Observable<Box[]> {
    return this.http.get(`${this.apiUrl}/boxes/allByName`)
      .pipe(
        map((response: any) => {
          const newState = this.mergeState(this.boxes, response.boxes.map(box => new Box(box)));
          this.updateBoxesState(newState);
          return newState;
        })
      );
  }

  // UTILS
  private mergeState(oldState: Box[], newState: Box[]) {
    if (oldState.length === 0) {
      return newState;
    }
    return newState.reduce((all, current) => {
      const temp = 'length' in all ? all : [];
      const index = temp.findIndex((t: Box) => t.id === current.id);
      if (index === -1) {
        return [...temp, current];
      } else {
        return [
          ...temp.slice(0, index),
          current,
          ...temp.slice(index + 1)
        ];
      }
    }, oldState);
  }
}
