import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Box } from '@app/models/box.model';

@Injectable({
  providedIn: 'root'
})
export class BoxesService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private httpClient: HttpClient,
  ) { }

  getDefaultBoxes(): Observable<Box[]> {
    return this.httpClient
      .get<{ boxes: Box[] }>(`${this.apiUrl}/boxes/type/default`)
      .pipe(map((result: any) => result.boxes.map(box => new Box(box))));
  }

  setDefaultBox(subscriptionId, boxId) {
    return this.httpClient
      .put<{ boxes: Box[] }>(`${this.apiUrl}/users/subscriptions/${subscriptionId}/set-default-box`, {
        boxId
      })
  }
}
