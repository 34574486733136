<app-bar-alert alerts-code="sl-alerts"></app-bar-alert>

<div class="row">
  <section class="container">
    <div class="row">
      <div class="col-sm-10">
        <p>
          Total shipments:
          {{ loadingShipmentsStats ? "Fetching..." : shipmentsStats.shipments }}
        </p>
      </div>

      <div class="col-sm-2">
        <button
          *ngIf="labelPrinterData?.allowManifest"
          class="btn"
          (click)="openManifestConfirmationModal()"
        >
          {{ loadingManifests ? "Loading" : "Create" }} manifest
        </button>
        <div class="col-sm-auto">
          <app-manifests-list></app-manifests-list>
        </div>
      </div>

      <div class="col-sm-12">
        <p>
          Total unprinted labels:
          {{
          loadingShipmentsStats
            ? "Fetching..."
            : shipmentsStats.unprintedShipments
          }}
        </p>
      </div>
    </div>
    <div class="row">
      <ul class="col-sm-12 list-container">
        <li class="row list-item">
          <div class="col-sm-3">User's name</div>
          <div class="col-sm-2">User's address</div>

          <div class="col-sm-auto ml-auto">
            <button
              class="labelSearcherTrigger button-animation pointer"
              type="button"
              [ngClass]="{
                'button-animation--active': downloadingPdfByTrackingCodes
              }"
              (click)="printByTrackingNumbers = true"
            >
              Print by tracking numbers
            </button>
          </div>
          <div class="col-sm-auto ml-auto">
            <button
              class="labelSearcherTrigger button-animation pointer"
              type="button"
              [ngClass]="{ 'button-animation--active': downloadingPdf }"
              (click)="showBuildsOptions = true"
            >
              Print build
            </button>
          </div>

          <div class="col-sm-auto">
            <app-builds-list></app-builds-list>
          </div>
        </li>

        <li
          class="row list-item"
          *ngFor="let shipment of filteredLabels(shipmentsLabelsRows)"
          [ngClass]="{ 'list-item--printed': shipment.printed }"
        >
          <div class="col-sm-3">{{ shipment.userName }}</div>
          <div class="col-sm-6">
            {{ (getShipmentAddress(shipment) | async)?.label }}
          </div>
          <div class="col-sm-auto ml-sm-auto">
            <button
              *ngIf="!shipment.refunded"
              class="list-item__button"
              (click)="onPrintLabel(shipment.shipmentId)"
            >
              Print label
            </button>
            <button
              *ngIf="shipment.refunded"
              style="cursor: not-allowed"
              class="list-item__button"
            >
              Label refunded
            </button>
          </div>
        </li>
      </ul>

      <div class="col-sm-12">
        <div class="loadMoreWrapper">
          <button
            class="loadMoreButton button-animation"
            [disabled]="loadingShipment"
            (click)="onLoadMore(page + 1)"
            [ngClass]="{ 'button-animation--active': loadingShipment }"
          >
            {{
            loadingShipment
              ? "Fetching more shipments"
              : "Load More shipments"
            }}
          </button>
        </div>
      </div>
    </div>
  </section>
</div>

<app-modal
  title="Printing options"
  [show]="showBuildsOptions"
  (atCloseBtn)="showBuildsOptions = false"
  secondaryBtn="Cancel"
  (atSecondaryBtn)="showBuildsOptions = false"
  primaryBtn="Print"
  [disablePrimaryBtn]="buildOptionsForm.invalid"
  (atPrimaryBtn)="onPrintBuild(buildOptionsForm.value)"
>
  <form
    [formGroup]="buildOptionsForm"
    novalidate
    (ngSubmit)="onPrintBuild($event.value)"
    class="row"
  >
    <div class="form-group col-sm-12">
      <label for="buildOptionsForm__date">1. Select Month:</label>
      <select
        formControlName="date"
        id="buildOptionsForm__date"
        class="form-control"
        *ngIf="shipmentsStats && shipmentsStats.monthlyStats"
      >
        <option value="ALL">All</option>
        <option *ngFor="let month of monthlyStatsList" [value]="month">
          {{ month }} (Available:{{ shipmentsStats.monthlyStats[month].total }},
          On Hold: {{ shipmentsStats.monthlyStats[month].onHold }})
        </option>
      </select>
      <small class="form-text text-muted"
      >Month when labels where created.</small
      >
    </div>

    <div class="form-group col-sm-12">
      <label for="buildOptionsForm__scnet">2. Select Plant/Plant Type:</label>
      <select
        formControlName="plant"
        id="buildOptionsForm__plant"
        class="form-control"
        *ngIf="optionStats && optionStats.plantStats && !loadingShipmentsStats"
      >
        <option value="ALL">All</option>
        <option *ngFor="let box of plantBoxes" [value]="optionStats.plantStats[box].value">
          {{ box }} (Available:{{ optionStats.plantStats[box].total }},
          On Hold: {{ optionStats.plantStats[box].onHold }})
        </option>
      </select>
      <small class="form-text text-muted"
      >Shipments with the selected plants</small
      >
    </div>

    <div class="form-group col-sm-12">
      <label>3. Select type:</label>

      <select name="option" id="" class="form-control" formControlName="option">
        <option *ngIf="showAll || optionStats.ALL?.available" value="ALL">ALL (Available: {{optionStats.ALL?.available}}, On Hold: {{optionStats.ALL?.onHold}})</option>
        <option *ngIf="showAll || optionStats.NEW?.available" value="NEW">NEW (Available: {{optionStats.NEW?.available}}, On Hold: {{optionStats.NEW?.onHold}})</option>
        <option *ngIf="showAll || optionStats.CUSTOM_BOX?.available" value="CUSTOM_BOX">Custom Name (Available: {{optionStats.CUSTOM_BOX?.available}}, On Hold: {{optionStats.CUSTOM_BOX?.onHold}})</option>
        <option *ngIf="showAll || optionStats.NEW_GIFT_GIFTIT?.available" value="NEW_GIFT_GIFTIT">NEW GIFT / GIFTIT (Available: {{optionStats.NEW_GIFT_GIFTIT?.available}}, On Hold: {{optionStats.NEW_GIFT_GIFTIT?.onHold}})</option>
<!--    *ngIf=showAll || optionStats.GIFT_WITH_PURCHASE?.available     <option value="GIFT_WITH_PURCHASE">GIFT WITH PURCHASE (Available: {{optionStats.GIFT_WITH_PURCHASE?.available}}, On Hold: {{optionStats.GIFT_WITH_PURCHASE?.onHold}})</option>-->
        <option *ngIf="showAll || optionStats.FUTURE_GIFT?.available" value="FUTURE_GIFT">FUTURE GIFT / GIFTIT (Available: {{optionStats.FUTURE_GIFT?.available}}, On Hold: {{optionStats.FUTURE_GIFT?.onHold}})</option>
        <option *ngIf="showAll || optionStats.GIFT_COMPLETE?.available" value="GIFT_COMPLETE">GIFT COMPLETE (Available: {{optionStats.GIFT_COMPLETE?.available}}, On Hold: {{optionStats.GIFT_COMPLETE?.onHold}})</option>
        <option *ngIf="showAll || optionStats.SCHED?.available" value="SCHED">SCHED (Available: {{optionStats.SCHED?.available}}, On Hold: {{optionStats.SCHED?.onHold}})</option>
        <option *ngIf="showAll || optionStats.RE_SHIP?.available" value="RE-SHIP">RE-SHIP (Available: {{optionStats.RE_SHIP?.available}}, On Hold: {{optionStats.RE_SHIP?.onHold}})</option>
        <option *ngIf="showAll || optionStats.GWP_AIR_PLANT?.available" value="GWP_AIR_PLANT">GWP: Air Plant (Available: {{optionStats.GWP_AIR_PLANT?.available}}, On Hold: {{optionStats.GWP_AIR_PLANT?.onHold}})</option>
        <option *ngIf="showAll || optionStats.GWP_PLANT_PIN?.available" value="GWP_PLANT_PIN">GWP: Plant pin (Available: {{optionStats.GWP_PLANT_PIN?.available}}, On Hold: {{optionStats.GWP_PLANT_PIN?.onHold}})</option>
        <option *ngIf="showAll || optionStats.GWP_FEBGFT?.available" value="GWP_FEBGFT">GWP: Feb Gift (Available: {{optionStats.GWP_FEBGFT?.available}}, On Hold: {{optionStats.GWP_FEBGFT?.onHold}})</option>
        <option *ngIf="showAll || optionStats.GWP_APRGFT?.available" value="GWP_APRGFT">GWP PIN: Wet Plants (Available: {{optionStats.GWP_APRGFT?.available}}, On Hold: {{optionStats.GWP_APRGFT?.onHold}})</option>
        <option *ngIf="showAll || optionStats.OTB_EUPHORBIA?.available" value="OTB_EUPHORBIA">OTB Euphorbia (Available: {{optionStats.OTB_EUPHORBIA?.available}}, On Hold: {{optionStats.OTB_EUPHORBIA?.onHold}})</option>

        // May OTBs 2021
        <option *ngIf="showAll || optionStats.BUNNY_CACTUS?.available" value="BUNNY_CACTUS">OTB Bunny Cactus + Pot (Available: {{optionStats.BUNNY_CACTUS?.available}}, On Hold: {{optionStats.BUNNY_CACTUS?.onHold}})</option>
        <option *ngIf="showAll || optionStats.DRIED_LAVENDER?.available" value="DRIED_LAVENDER">OTB Dried Lavender (Available: {{optionStats.DRIED_LAVENDER?.available}}, On Hold: {{optionStats.DRIED_LAVENDER?.onHold}})</option>

        // June OTBs 2021
        <option *ngIf="showAll || optionStats.OTB_ECH?.available" value="OTB_ECH">OTB 2 Potted Succulents (Available: {{optionStats.OTB_ECH?.available}}, On Hold: {{optionStats.OTB_ECH?.onHold}})</option>

        // July OTBs 2021
        <option *ngIf="showAll || optionStats.SS_4TH_JULY?.available" value="SS_4TH_JULY">OTB 4th July (Available: {{optionStats.SS_4TH_JULY?.available}}, On Hold: {{optionStats.SS_4TH_JULY?.onHold}})</option>
        <option *ngIf="showAll || optionStats.BEGONIA_BOX?.available" value="BEGONIA_BOX">OTB Begonia box (Available: {{optionStats.BEGONIA_BOX?.available}}, On Hold: {{optionStats.BEGONIA_BOX?.onHold}})</option>

        // AUG OTB 2021
        <option *ngIf="showAll || optionStats.OTB_PILEA?.available" value="OTB_PILEA">OTB Pilea Chinese Money Tree Box (Available: {{optionStats.OTB_PILEA?.available}}, On Hold: {{optionStats.OTB_PILEA?.onHold}})</option>
        <option *ngIf="showAll || optionStats.OTB_LAVENDER_WHEAT?.available" value="OTB_LAVENDER_WHEAT">OTB Lavender + Wheat Bundle Box (Available: {{optionStats.OTB_LAVENDER_WHEAT?.available}}, On Hold: {{optionStats.OTB_LAVENDER_WHEAT?.onHold}})</option>
        <option *ngIf="showAll || optionStats.OTB_GOLDEN_EUONYMUS_BUNDLE?.available" value="OTB_GOLDEN_EUONYMUS_BUNDLE">OTB Golden Euonymus Bundle (Available: {{optionStats.OTB_GOLDEN_EUONYMUS_BUNDLE?.available}}, On Hold: {{optionStats.OTB_GOLDEN_EUONYMUS_BUNDLE?.onHold}})</option>

<!--        NOV OTB 2021-->
        <option *ngIf="showAll || optionStats.OTB_ROSEMARY_BUNDLE_BOX?.available" value="OTB_ROSEMARY_BUNDLE_BOX">OTB Rosemary Bundle box (Available: {{optionStats.OTB_ROSEMARY_BUNDLE_BOX?.available}}, On Hold: {{optionStats.OTB_ROSEMARY_BUNDLE_BOX?.onHold}})</option>

<!--        JUN OTB 2022-->
        <option *ngIf="showAll || optionStats.SUN_SPARKLER?.available" value="SUN_SPARKLER">OTB SunSparkler Box (Available: {{optionStats.SUN_SPARKLER?.available}}, On Hold: {{optionStats.SUN_SPARKLER?.onHold}})</option>

<!--        JUL OTB 2022-->
        <option *ngIf="showAll || optionStats.OTB_SILVER_DOLLAR_JULY?.available" value="OTB_SILVER_DOLLAR_JULY">OTB Silver Dollar (Available: {{optionStats.OTB_SILVER_DOLLAR_JULY?.available}}, On Hold: {{optionStats.OTB_SILVER_DOLLAR_JULY?.onHold}})</option>

<!--        Aug OTB 2022-->
        <option *ngIf="showAll || optionStats.OTB_CANDY_PLANT?.available" value="OTB_CANDY_PLANT">OTB Cotton Candy  (Available: {{optionStats.OTB_CANDY_PLANT?.available}}, On Hold: {{optionStats.OTB_CANDY_PLANT?.onHold}})</option>

        <!--        Sep OTB 2022-->
        <option value="OTB_BELLE_DONKEY" *ngIf="showAll || optionStats.OTB_BELLE_DONKEY?.available">
          OTB Pachyveria 'Belle Blue' + Sedum 'Donkey's Tail': {{optionStats.OTB_BELLE_DONKEY?.available}}, On
          Hold: {{optionStats.OTB_BELLE_DONKEY?.onHold}})
        </option>
        <option value="OTB_PADDLE_DEDOS" *ngIf="showAll || optionStats.OTB_PADDLE_DEDOS?.available">
          OTB Kalanchoe 'Paddle Plant' + Sedum 'Dedos': {{optionStats.OTB_PADDLE_DEDOS?.available}}, On
          Hold: {{optionStats.OTB_PADDLE_DEDOS?.onHold}})
        </option>
        <option value="OTB_TACO_CARPET" *ngIf="showAll || optionStats.OTB_TACO_CARPET?.available">
          OTB Peperomia 'Taco Plant' + Crassula 'Small Red Carpet': {{optionStats.OTB_TACO_CARPET?.available}}, On
          Hold: {{optionStats.OTB_TACO_CARPET?.onHold}})
        </option>
        <option value="OTB_FANG_KIWI" *ngIf="showAll || optionStats.OTB_FANG_KIWI?.available">
          OTB Kalanchoe 'Fang' + Aeonium 'Kiwi': {{optionStats.OTB_FANG_KIWI?.available}}, On
          Hold: {{optionStats.OTB_FANG_KIWI?.onHold}})
        </option>
        <option value="OTB_PROLIFICA_JEWEL" *ngIf="showAll || optionStats.OTB_PROLIFICA_JEWEL?.available">
          OTB Echeveria 'Prolifica' + Pachyphytum 'Little Jewel': {{optionStats.OTB_PROLIFICA_JEWEL?.available}}, On
          Hold: {{optionStats.OTB_PROLIFICA_JEWEL?.onHold}})
        </option>
        <option value="OTB_SPOONS_DARLEY" *ngIf="showAll || optionStats.OTB_SPOONS_DARLEY?.available">
          OTB Kalanchoe 'Copper Spoons' + Graptosedum 'Darley Sunshine': {{optionStats.OTB_SPOONS_DARLEY?.available}}, On
          Hold: {{optionStats.OTB_SPOONS_DARLEY?.onHold}})
        </option>

        <!--        July 2023-->
        <option value="OTB_MINIMA_DRAGON" *ngIf="showAll || optionStats.OTB_MINIMA_DRAGON?.available">
          The Minima + Dragon Box: {{optionStats.OTB_MINIMA_DRAGON?.available}}, On
          Hold: {{optionStats.OTB_MINIMA_DRAGON?.onHold}})
        </option>

        <!--        Aug 2023-->
        <option value="OTB_BZ_TREE" *ngIf="showAll || optionStats.OTB_BZ_TREE?.available">
          OTB Brazilian Tree Fern Box: {{optionStats.OTB_BZ_TREE?.available}}, On
          Hold: {{optionStats.OTB_BZ_TREE?.onHold}})
        </option>

        <!--        October 2023-->
        <option value="OTB_2XSTRG" *ngIf="showAll || optionStats.OTB_2XSTRG?.available">
          OTB 2X String of Pearls Box: {{optionStats.OTB_2XSTRG?.available}}, On
          Hold: {{optionStats.OTB_2XSTRG?.onHold}})
        </option>

        <option *ngIf="showAll || optionStats.OTB_BERZELIA?.available" value="OTB_BERZELIA">OTB BERZELIA Bundle Box (Available: {{optionStats.OTB_BERZELIA?.available}}, On Hold: {{optionStats.OTB_BERZELIA?.onHold}})</option>
        <option *ngIf="showAll || optionStats.OTB_TIGER_TOOTH_BOX?.available" value="OTB_TIGER_TOOTH_BOX">OTB Tiger Tooth Aloe Box (Available: {{optionStats.OTB_TIGER_TOOTH_BOX?.available}}, On Hold: {{optionStats.OTB_TIGER_TOOTH_BOX?.onHold}})</option>
      </select>
      <div class="form-group form-check text-right">
        <input type="checkbox" class="form-check-input" id="showAllOptions" [(ngModel)]="showAll"
               [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="showAllOptions">Show All Options.</label>
      </div>
    </div>

    <!--<div class="form-group col-sm-12">
      <div><label>4. Select Carrier:</label></div>
      <div
        *ngFor="
          let rateObject of labelPrinterData?.defaultBuildRates;
          let i = index
        "
        class="form-check form-check-inline"
      >
        <input
          class="form-check-input"
          type="radio"
          id="carrierOption{{ i }}"
          formControlName="selectedCarrier"
          [value]="rateObject.carrier"
        />
        <label class="form-check-label" for="carrierOption{{ i }}">{{
          rateObject.name
          }}</label>
      </div>
    </div>-->

    <div class="form-group col-sm-12">
      <label for="buildOptionsForm">Quantity of labels:</label>
      <input
        type="number"
        class="form-control"
        id="buildOptionsForm"
        formControlName="quantity"
        min="0"
        max="100"
        value="100"
        [ngClass]="{ 'is-invalid': !buildOptionsForm.get('quantity').valid }"
      />
      <small class="form-text text-muted">
        {{
        buildOptionsForm.get("quantity").valid
          ? "Total labels to print. Write a number between 0 and 100"
          : "Please write a number between 0 and 100"
        }}
      </small>
    </div>

    <!-- <div class="form-group col-sm-12">
      <label for="buildOptionsForm__date">Select custom Carrier:</label>
      <input type="checkbox" formControlName="customCarrier" #customCarrier>
    </div>

    <div class="form-group col-sm-12" *ngIf="customCarrier.checked">
      <label>Available Carriers</label>
      <div class="form-group">
        <label for="buildOptionsForm__date">Select Carrier:</label>
        <select
          formControlName="selectedCarrier" class="form-control">
          <option
            *ngFor="let carrier of carrierList"
            [value]="carrier.carrierName"
            [selected]="buildOptionsForm.get('selectedCarrier').value === carrier.carrierName">
            {{ carrier.carrierName }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="buildOptionsForm__date">Select Service:</label>
        <select formControlName="selectedService" class="form-control">
          <option
            *ngFor="let service of carrierSelectedServices"
            [value]="service"
            [selected]="buildOptionsForm.get('selectedService').value === service">
            {{ service }}
          </option>
        </select>
      </div>
    </div> -->
  </form>
</app-modal>

<app-modal
  title="Print By Tracking Numbers"
  secondaryBtn="Cancel"
  primaryBtn="Submit & Print"
  (atCloseBtn)="printByTrackingNumbers = false"
  (atSecondaryBtn)="printByTrackingNumbers = false"
  [show]="printByTrackingNumbers"
  (atPrimaryBtn)="submitTrackingNumbers()"
>
  <form novalidate class="row">
    <div class="form-group col-sm-6">
      <label>List of tracking Numbers</label>
      <input
        #csvInputFile
        name="csvInputFile"
        required
        type="file"
        class="form-control-file"
        accept="text/csv"
      />
    </div>
  </form>
</app-modal>


<ng-container *ngIf="isManifestConfirmationModalActive">
  <div
    class="modal"
    [ngStyle]="{
      'display': 'block',
      'backgroundColor': 'rgba(0,0,0,.8)'
    }"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button (click)="closeManifestConfirmationModal()" aria-label="Close" class="close" data-dismiss="modal"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to create a manifest?</p>
        </div>
        <div class="modal-footer">
          <button (click)="getTodayShipmentsManifest()" [disabled]="loadingManifests" class="btn btn-primary"
                  type="button">
            {{ loadingManifests ? 'Loading...' : 'Yes'}}
          </button>
          <button (click)="closeManifestConfirmationModal()" [disabled]="loadingManifests" class="btn btn-secondary"
                  type="button">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
