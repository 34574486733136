/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./shared/pipes/safe-html.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./app.component";
import * as i7 from "@ngrx/store";
import * as i8 from "./services/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "header-logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Log out "]))], null, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 44, "ul", [["class", "row justify-content-start adm-nav-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "li", [["class", "col-sm-auto adm-nav-list__item"], ["routerLink", "/"], ["routerLinkActive", "semi-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), (_l()(), i1.ɵted(-1, null, [" Search customer "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "li", [["class", "col-sm-auto adm-nav-list__item"], ["routerLink", "/shipment-labels"], ["routerLinkActive", "semi-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(10, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Labels Printer "])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "li", [["class", "col-sm-auto adm-nav-list__item"], ["routerLink", "/shipment-creator"], ["routerLinkActive", "semi-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, [[5, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(16, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Shipment creator "])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "li", [["class", "col-sm-auto adm-nav-list__item"], ["routerLink", "/coupons-manager"], ["routerLinkActive", "semi-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, [[7, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(22, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Coupons Manager "])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "li", [["class", "col-sm-auto adm-nav-list__item"], ["routerLink", "/rates-manager"], ["routerLinkActive", "semi-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 16384, [[9, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(28, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 9, { links: 1 }), i1.ɵqud(603979776, 10, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Rates Manager "])), (_l()(), i1.ɵeld(32, 0, null, null, 5, "li", [["class", "col-sm-auto adm-nav-list__item"], ["routerLink", "/tokens-manager"], ["routerLinkActive", "semi-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 33).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 16384, [[11, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(34, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 11, { links: 1 }), i1.ɵqud(603979776, 12, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" Tokens "])), (_l()(), i1.ɵeld(38, 0, null, null, 6, "li", [["class", "col-sm-auto adm-nav-list__item"], ["routerLink", "/tokens-manager"], ["routerLinkActive", "semi-bold"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 39).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(39, 16384, [[13, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(40, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 13, { links: 1 }), i1.ɵqud(603979776, 14, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(43, 0, null, null, 1, "a", [["href", "/v2/otb-dashboard"], ["style", "color: black; text-decoration: none"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["OTB Dashboard"]))], function (_ck, _v) { var currVal_0 = "/"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, true); var currVal_2 = "semi-bold"; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = "/shipment-labels"; _ck(_v, 9, 0, currVal_3); var currVal_4 = "semi-bold"; _ck(_v, 10, 0, currVal_4); var currVal_5 = "/shipment-creator"; _ck(_v, 15, 0, currVal_5); var currVal_6 = "semi-bold"; _ck(_v, 16, 0, currVal_6); var currVal_7 = "/coupons-manager"; _ck(_v, 21, 0, currVal_7); var currVal_8 = "semi-bold"; _ck(_v, 22, 0, currVal_8); var currVal_9 = "/rates-manager"; _ck(_v, 27, 0, currVal_9); var currVal_10 = "semi-bold"; _ck(_v, 28, 0, currVal_10); var currVal_11 = "/tokens-manager"; _ck(_v, 33, 0, currVal_11); var currVal_12 = "semi-bold"; _ck(_v, 34, 0, currVal_12); var currVal_13 = "/tokens-manager"; _ck(_v, 39, 0, currVal_13); var currVal_14 = "semi-bold"; _ck(_v, 40, 0, currVal_14); }, null); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "header", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "section", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "figure", [["class", "col-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "logo_x2"], ["class", "header-img"], ["src", "/assets/images/logos/logo-transparent.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "col-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h2", [["class", "header-title"], ["routerLink", "/"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵppd(11, 1), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "col-sm text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_3)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 6, 0, currVal_2); var currVal_4 = "/"; _ck(_v, 10, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.admin$)).isAuthenticated; _ck(_v, 14, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.admin$)).isAuthenticated; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), ((_co.appData == null) ? null : _co.appData.title))); _ck(_v, 9, 0, currVal_3); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeHtmlPipe, [i5.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpad(3, 3), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_ck(_v, 3, 0, "/churn", "/sales", "/media").includes(_co.router.url); _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i2.Router, i7.Store, i8.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
