import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Models
import { Shipment, ShipmentStats } from '@app/models/shipment.model';
import { Address } from '@app/models/address.model';
import { SocketIoService } from '@app/core/services';

@Injectable()
export class ShipmentService {
  private apiUrl = environment.apiUrl + '/api';
  private shipmentsState: Shipment[] = [];
  private shipmentsSubject: BehaviorSubject<Shipment[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private readonly ioService: SocketIoService,
  ) {
  }

  private get shipments(): Shipment[] {
    return this.shipmentsState.slice();
  }

  private set shipments(data: Shipment[]) {
    this.shipmentsState = data;
    this.shipmentsSubject.next(data);
  }

  public getShipmentsState(): Observable<Shipment[]> {
    console.log('ShipmentService::getShipmentsState');
    return this.shipmentsSubject;
  }

  public updateShipmentsState(data: Shipment[]) {
    console.log('ShipmentService::updateShipmentsState');
    this.shipments = data;
  }

  public updateShipmentItem(shipment: Shipment) {
    this.shipments = this.shipments.map(s => {
      if (s.id === shipment.id) {
        return shipment;
      }
      return s;
    });
  }

  public fecthShipmentsFromApi(params: FetchShipmentsParams) {
    console.log('ShipmentService::fecthShipmentsFromApi');
    let httpParams = new HttpParams();
    if (typeof params.limit === 'number') {
      httpParams = httpParams.append('limit', params.limit.toString());
    }
    if (typeof params.page === 'number') {
      httpParams = httpParams.append('page', params.page.toString());
    }
    return this.http.get(`${ this.apiUrl }/admin/shipments/`, {params: httpParams})
      .pipe(map((response: any) => {
        const newShipments = response.data.map(shipment => new Shipment(shipment));
        this.shipments = this.mergeShipments(this.shipments, newShipments);
        return newShipments;
      }));
  }

  public fetchShipmentRates(shipmentId: string) {
    return this.http.get(`${ this.apiUrl }/shipments/${ shipmentId }/rates`)
      .pipe(map((response: any) => response.data));
  }

  public updateShipment(shipmentId: string, shipment: any) {
    return this.http.put(`${ this.apiUrl }/admin/shipments/${ shipmentId }`, shipment)
      .pipe(map((response: any) => {
        const newShipment = new Shipment(response);
        this.shipments = this.mergeShipments(this.shipments, [newShipment]);
        return newShipment;
      }));
  }

  public setShipmentAsPrinted(shipmentId: string): Observable<Shipment> {
    const body = {
      user: 'Admin'
    };
    return this.http.put(`${ this.apiUrl }/admin/shipments/${ shipmentId }/print`, body)
      .pipe(map((response: any) => {
        const newShipment = new Shipment(response.data);
        this.shipments = this.mergeShipments(this.shipments, [newShipment]);
        return newShipment;
      }));
  }

  public fetchShipmentLabelToPrint(shipmentId: string) {
    console.log('ShipmentService::fetchShipmentLabelToPrint');
    return this.http.get(`${ this.apiUrl }/admin/shipments/${ shipmentId }/pdf-to-print`)
      .pipe(map((response: any) => response.data));
  }

  public fetchShipmentLabelPrinted(shipmentId: string) {
    console.log('ShipmentService::fetchShipmentLabelPrinted');
    return this.http.get(`${ this.apiUrl }/admin/shipment/${ shipmentId }/generated-label`)
      .pipe(map((response: any) => response.data));
  }

  public fetchUnprintedShipmentsPdf(options: LabelBuildOptions) {
    console.log('ShipmentService::fetchUnprintedShipmentsPdf');
    let httpParams = new HttpParams();
    httpParams = httpParams.append('option', options.option);
    httpParams = httpParams.append('date', options.date.toString());
    httpParams = httpParams.append('quantity', options.quantity.toString());
    if (options.selectedCarrier && options.selectedService) {
      httpParams = httpParams.append('selectedCarrier', options.selectedCarrier.toString());
      httpParams = httpParams.append('selectedService', options.selectedService.toString());
    }
    httpParams = httpParams.append('socketId', this.ioService.socketId);
    httpParams = httpParams.append('plant', options.plant);

    // if (options.customCarrier) {
    //   httpParams = httpParams.append('selectedCarrier', options.selectedCarrier.toString());
    //   httpParams = httpParams.append('selectedService', options.selectedService.toString());
    // }

    return this.http.get(`${ this.apiUrl }/admin/shipments/unprinted/pdf-to-print`, {params: httpParams})
      .pipe(map((response: any) => response.data));
  }

  fetchShipmentLabelByTrackingNumbers(trackingCodes) {
    return this.http.post(`${ this.apiUrl }/admin/shipments/pdf-to-print-by-tracking-codes`, {trackingCodes}).pipe(
      map((response: any) => response.data));
  }

  public fetchShipmentsByUsers(userIds = [], page = 0, limit = 25) {
    console.log('ShipmentService::fetchShipmentsByUsers');
    let httpParams = new HttpParams();
    if (typeof limit === 'number') {
      httpParams = httpParams.append('limit', limit.toString());
    }
    if (typeof page === 'number') {
      httpParams = httpParams.append('page', page.toString());
    }
    return this.http.post(`${ this.apiUrl }/admin/shipments/by-users`, {userIds}, {params: httpParams})
      .pipe(map((response: any) => {
        const newShipments = response.data.map(shipment => new Shipment(shipment));
        this.shipments = this.mergeShipments(this.shipments, newShipments);
        return newShipments;
      }));
  }

  public refundShipmentById(shipmentId: string): Observable<Shipment> {
    console.log('ShipmentService::refundShipmentById');
    return this.http.put(`${ this.apiUrl }/admin/shipments/${ shipmentId }/refund`, {})
      .pipe(map((response: any) => {
        const newShipments = new Shipment(response.data);
        this.shipments = this.mergeShipments(this.shipments, [newShipments]);
        return newShipments;
      }));
  }

  public reshipShipmentById(data: { shipmentId: string, reasons: any[], refundCurrentLabel: boolean }): Observable<Shipment[]> {
    console.log('ShipmentService::reshipShipmentById');
    return this.http.put(`${ this.apiUrl }/admin/shipments/${ data.shipmentId }/reship`, {
      reasons: data.reasons,
      refundCurrentLabel: data.refundCurrentLabel
    })
      .pipe(map((response: any) => {
        const newShipments: Shipment[] = response.data.map(shipment => new Shipment(shipment));
        this.shipments = this.mergeShipments(this.shipments, newShipments);
        return newShipments;
      }));
  }

  public createFreeShipment(data: CreateShipmentParams): Observable<Shipment> {
    console.log('ShipmentService::createFreeShipment');
    return this.http.post(`${ this.apiUrl }/admin/shipments/`, data)
      .pipe(map((response: any) => {
        const newShipments = new Shipment(response.data);
        this.shipments = this.mergeShipments(this.shipments, [newShipments]);
        return newShipments;
      }));
  }

  fetchCustomShipmentsFromApi(page: number = 0) {
    const httpParams = new HttpParams().append('page', page.toString());
    return this.http.get(`${ this.apiUrl }/admin/custom-shipments/`, {
      params: httpParams
    })
      .pipe(map((response: any) => response.data));
  }

  createCustomShipment(shipmentsData: any) {
    return this.http.post(`${ this.apiUrl }/admin/custom-shipments/`, shipmentsData)
      .pipe(map((response: any) => response.data));
  }

  createShipment(shipmentsData: any) {
    return this.http.post(`${ this.apiUrl }/shipments/rates/`, shipmentsData)
      .pipe(map((response: any) => response));
  }

  buyShipment(shipmentsData: { id: string, rate: string }) {
    return this.http.post(`${ this.apiUrl }/shipments/custom-buy/`, shipmentsData)
      .pipe(map((response: any) => response));
  }

  public duplicateCustomShipment(id: string) {
    return this.http.post(`${ this.apiUrl }/admin/custom-shipments/${ id }/duplicate`, {})
      .pipe(map((response: any) => response.data));
  }

  public fetchShipmentsStatsFromApi() {
    console.log('ShipmentService::fetchShipmentsStatsFromApi');
    return this.http.get(`${ this.apiUrl }/admin/shipments/stats`)
      .pipe(map((response: any): ShipmentStats => new ShipmentStats(response.data)));
  }

  public fetchShipmentsStatsByTypeFromApi(selectedDate, plant?: string) {
    console.log('ShipmentService::fetchShipmentsStatsFromApi');
    const params: { date: string, plant?: string } = {date: selectedDate};
    if (plant) {
      params.plant = plant;
    }
    return this.http.get(`${ this.apiUrl }/admin/shipments/stats-type`, {params})
      .pipe(map((response: any) => response.data));
  }

  public buyShipmentLabel(
    subscriptionId: number,
    shipmentId: string,
    rate: any = {carrier: 'USPS', service: 'First'}
  ): Observable<Shipment> {
    return this.http.put(`${ this.apiUrl }/admin/shipments/${ shipmentId }/buy-label`, {
      subscriptionId,
      shipmentId,
      rate
    })
      .pipe(map((response: any) => {
        const newShipment = new Shipment(response.data);
        this.shipments = this.mergeShipments(this.shipments, [newShipment]);
        return newShipment;
      }));
  }

  public changeShipmentPrintingDate(shipmentId: string, cantPrintUntil): Observable<Shipment> {
    console.log('ShipmentService::changeShipmentPrintingDate');
    return this.http.put(`${ this.apiUrl }/admin/shipments/${ shipmentId }/printing-date`, {
      cantPrintUntil
    })
      .pipe(map((response: any) => {
        const newShipment = new Shipment(response.data);
        this.shipments = this.mergeShipments(this.shipments, [newShipment]);
        return newShipment;
      }));
  }

  public getTodayShipmentManifest(): Observable<string[]> {
    return this.http.get(`${ this.apiUrl }/admin/shipments/manifest/today`)
      .pipe(map((response: any) => response.data));
  }

  // UTILS
  mergeShipments(oldShipments: Shipment[], newShipments: Shipment[]) {
    if (oldShipments.length === 0) {
      return newShipments;
    }
    return newShipments.reduce((allShipments, newShipment) => {
      const temp = 'length' in allShipments ? allShipments : [];
      const shipmentIndex = temp.findIndex((shipment: Shipment) => shipment.id === newShipment.id);
      if (shipmentIndex === -1) {
        return [...temp, newShipment];
      } else {
        return [
          ...temp.slice(0, shipmentIndex),
          newShipment,
          ...temp.slice(shipmentIndex + 1)
        ];
      }
    }, oldShipments);
  }

  updateShipmentAddress(shipmentId, address): Observable<{ shipment: Shipment, address: Address }> {
    return this.http
      .put(`${ this.apiUrl }/admin/shipments/${ shipmentId }/address`, {address})
      .pipe(map((result: any) => {
          return {
            address: new Address(result.address),
            shipment: new Shipment(result.shipment),
          };
        })
      );
  }
}

export interface FetchShipmentsParams {
  limit: number | undefined;
  page: number | undefined;
}

export interface LabelBuildOptions {
  option: '0' | '1' | '2' | '3' | '4';
  date: 'all' | number;
  quantity: number;
  customCarrier: boolean;
  selectedCarrier?: string;
  selectedService?: string;
  plant?: string;
}

export interface CreateShipmentParams {
  subscription: number;
  customer: string;
  address: any;
}
