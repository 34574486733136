<header *ngIf="!['/churn','/sales','/media'].includes(router.url)" class="container-fluid">
  <div class="row">
    <section class="container">
      <div class="row align-items-center">
        <figure class="col-sm">
          <a routerLink="/">
            <img class="header-img" src="/assets/images/logos/logo-transparent.png" alt="logo_x2">
          </a>
        </figure>
        <div class="col-sm">
          <h2 routerLink="/" class="header-title" [innerHTML]="appData?.title | safeHTML"></h2>
        </div>
        <div class="col-sm text-right">
          <button (click)="logOut()" *ngIf="(admin$ | async).isAuthenticated" class="header-logout">
            Log out
          </button>
        </div>
      </div>

      <ul *ngIf="(admin$ | async).isAuthenticated" class="row justify-content-start adm-nav-list">
        <li [routerLinkActiveOptions]="{ exact: true }" class="col-sm-auto adm-nav-list__item" routerLink="/"
            routerLinkActive="semi-bold">
          Search customer
        </li>
        <li class="col-sm-auto adm-nav-list__item" routerLink="/shipment-labels" routerLinkActive="semi-bold">
          Labels Printer
        </li>
        <li class="col-sm-auto adm-nav-list__item" routerLink="/shipment-creator" routerLinkActive="semi-bold">
          Shipment creator
        </li>
        <li class="col-sm-auto adm-nav-list__item" routerLink="/coupons-manager" routerLinkActive="semi-bold">
          Coupons Manager
        </li>
        <li class="col-sm-auto adm-nav-list__item" routerLink="/rates-manager" routerLinkActive="semi-bold">
          Rates Manager
        </li>
        <li class="col-sm-auto adm-nav-list__item" routerLink="/tokens-manager" routerLinkActive="semi-bold">
          Tokens
        </li>
        <li class="col-sm-auto adm-nav-list__item" routerLink="/tokens-manager" routerLinkActive="semi-bold">
          <a href="/v2/otb-dashboard" target="_blank" style="color: black; text-decoration: none">OTB Dashboard</a>
        </li>
      </ul>
    </section>
  </div>
</header>

<router-outlet></router-outlet>
